import React, {useContext, useEffect, useState} from "react";
import SortableTree, {
    addNodeUnderParent,
    changeNodeAtPath,
    getTreeFromFlatData
} from "@nosferatu500/react-sortable-tree";
import {Button} from "primereact/button";
import {ScrollPanel} from "primereact/scrollpanel";
import _ from "lodash";
import {Generator} from "../service/Generator";
import {AppContext} from "../service/AppContext";
import styles from "../App.module.css";
import {confirmDialog} from "primereact/confirmdialog";

const nodeTypes = {
    'profession': { label: 'Профессия', minLevel: 0, maxLevel: 0, next: ['sprint'] },
    'sprint': { label: 'Спринт', minLevel: 1, maxLevel: 1, next: ['theme','project'], altName: ({parent}) => (parent === 'COMMON') ? 'Модуль' : 'Спринт' },
    'theme': { label: 'Тема', minLevel: 2, maxLevel: 2, next: ['lesson'] },
    'lesson': { label: 'Урок', minLevel: 3, maxLevel: 3, next: ['task'] },
    'task': { label: 'Урок', minLevel: 4, maxLevel: 4, next: [] },
    'project': { label: 'Проект', minLevel: 2, maxLevel: 2, next: [] },
    'link': { label: 'Ссылка', minLevel: 1, maxLevel: 3, next: [] }
};

const CachedInput = ({ value, onChange, ...props }) => {
    const [state, setState] = useState("");

    useEffect(() => {
        setState(value);
    }, [value]);

    return <input
        {...props}
        value={state}
        onChange={(event) => setState(event.target.value)}
        onBlur={(event) => {
            if (state !== value) {
                onChange(state);
            }
        }}
    />
};

export function Tree({onOpen, tags, onChange}) {
    const [state, setState] = useState([]);
    const [isChanged, setChanged] = useState(false);

    const { api, selectedBranch, draft } = useContext(AppContext);

    const items = [
        /*{
            label: 'Обновить',
            icon: 'pi pi-refresh',
            command: () => {
                //toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
            }
        },*/
        {
            label: 'Удалить',
            icon: 'pi pi-trash',
            command: () => {
                console.log('delete tree item');
                //toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
            }
        },
        {
            label: 'Открыть',
            icon: 'pi pi-external-link',
            command: () => {
                console.log('open tree item');
                //window.location.href = 'https://facebook.github.io/react/';
            }
        },
        /*{
            label: 'Импортировать',
            icon: 'pi pi-file-import',
            command: () => {}
        }*/
    ];

    useEffect(() => {
        if (draft && draft.has('tree')) {
            const draftTree = draft.get('tree');
            if (draftTree && draftTree.length > 0) {
                return setState(draftTree);
            }
        }

        setState(getTreeFromFlatData({
            flatData: api.content,
            rootKey: null
        }));
    }, [selectedBranch]);

    useEffect(() => {
        if (isChanged) {
            draft.set('tree', [...state]);
            setChanged(false);
        }
    }, [state, isChanged, draft]);

    return <ScrollPanel style={{height: 'calc(100% - 40px - 34px - 32px)', width: '100%'}}>
        <SortableTree
            treeData={state}
            onChange={treeData => {
                //console.log(`change`);
                setState(treeData);
            }}
            onMoveNode={({ node, nextParentNode, prevTreeIndex, nextTreeIndex }) => {
                if (node.imported) {
                    if (nextParentNode) {
                        let pathName = nextParentNode.pathName.replace('README.md', '');
                        let nodeName = node.title.split('.').slice(1).join('').trim();
                        let id = pathName + nodeName + '/README.md';
                        onChange({action: 'import', id, provider: 'notion', from: node.id});
                    }
                } else {
                    onChange({action: 'move', id: node.pathName, from: prevTreeIndex, to: nextTreeIndex});
                }
                setChanged(true);
            }}
            searchMethod={({ node, searchQuery }) => {
                if (!searchQuery) return undefined;
                return searchQuery
                    .map(tag => node.title.toLowerCase().includes(tag.toLowerCase()) || node.template === tag)
                    .filter(found => found)
                    .length > 0;
            }}
            searchQuery={(tags.length > 0) ? tags : undefined }
            onlyExpandSearchedNodes={true}
            dndType={"content-item"}
            //canDrag={() => isEditable}
            canDrop={({prevParent, nextParent, node}) => {
                //if (!isEditable) return false;
                if (node.imported) return true;
                if ((prevParent && prevParent.template) && (nextParent && nextParent.template)) {
                    return prevParent.template === nextParent.template;
                } else return false;
            }}
            canNodeHaveChildren={(node) => {
                return !['lesson', 'project'].includes(node.template);
            }}
            generateNodeProps={({node, path}) => ({
                className: `node-type-${node.template}`,
                buttons: [
                    ['lesson', 'project'].includes(node.template) ?
                        <Button
                            icon="pi pi-arrow-circle-right"
                            rounded
                            text
                            onClick={() => onOpen({
                                id: node.pathName,
                                title: node.title,
                                template: node.template
                            })}
                        /> : null,
                    ['theme', 'sprint', 'profession'].includes(node.template) ?
                        <Button
                            icon="pi pi-plus"
                            rounded
                            text
                            onClick={() => {
                                const newNode = {
                                    title: `New content`,
                                    id: `${node.id}/${node.treeIndex}`,
                                    parentId: node.id,
                                    template: nodeTypes[node.template].next[0] ?? ''
                                };

                                setState(addNodeUnderParent({
                                    treeData: state,
                                    parentKey: path[path.length - 1],
                                    expandParent: true,
                                    getNodeKey: ({treeIndex}) => treeIndex,
                                    newNode,
                                    addAsFirstChild: false,
                                }).treeData);
                                onChange({ action: 'add', ...newNode });
                                setChanged(true);
                            }}
                        /> : null,
                ].filter(it => !!it),
                title: (<label>
                    <span>{nodeTypes[node.template].label}: </span>
                    <CachedInput
                        className={styles.input}
                        value={node.title}
                        onChange={(title) => {
                            if (node.title !== title) {
                                onChange({ action: 'rename', id: node.pathName, from: node.title, to: title });
                                setState(changeNodeAtPath({
                                    treeData: state,
                                    path,
                                    getNodeKey: ({treeIndex}) => treeIndex,
                                    newNode: {...node, title},
                                }));
                                setChanged(true);
                            }
                        }}
                    />
                </label>),
            })}
        />
    </ScrollPanel>
}

export function ImportedTree({data, onOpen}) {
    const [state, setState] = useState(data ?? []);

    return <ScrollPanel style={{height: 'calc(100% - 40px - 34px - 32px)', width: '100%'}}>
        <SortableTree
            treeData={state}
            onChange={treeData => {
                //console.log(`change`);
                setState(treeData);
            }}
            dndType={"content-item"}
            generateNodeProps={({node}) => ({
                className: `node-type-${node.template}`,
                buttons: [
                    <Button
                        icon="pi pi-arrow-circle-right"
                        rounded
                        text
                        onClick={() => onOpen(node)}
                    />
                ].filter(it => !!it),
                title: (<span>
                    {node.title}
                </span>),
            })}
        />
    </ScrollPanel>
}