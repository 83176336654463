import styles from "./styles.module.css";
import {Avatar} from "primereact/avatar";
import React, {useContext} from "react";
import {AppContext} from "../service/AppContext";

export function Profile() {
    const { userProfile } = useContext(AppContext);

    return <div
            className={styles.avatar}
            data-pr-tooltip={userProfile.email}
            data-pr-position="bottom"
        >
            <span>{userProfile.name}</span>
            <Avatar image={userProfile.avatar} />
        </div>
}