import React, {useContext, useEffect, useState} from "react";
import SortableTree, {
    changeNodeAtPath,
} from "@nosferatu500/react-sortable-tree";
import {Button} from "primereact/button";
import {ScrollPanel} from "primereact/scrollpanel";
import styles from "../App.module.css";

export function TreeView({onOpen, items}) {
    const [state, setState] = useState({
        treeData: items ?? []
    });

    return <ScrollPanel style={{height: 'calc(100% - 40px - 34px - 32px)', width: '100%'}}>
        <SortableTree
            treeData={state.treeData}
            onChange={treeData => {
                console.log(`change`);
                setState({treeData});
            }}
            canDrop={({prevParent, nextParent}) => {
                return true;
            }}
            canNodeHaveChildren={(node) => {
                return false;
            }}
            generateNodeProps={({node, path}) => ({
                className: `node-type-${node.template}`,
                buttons: [
                    <Button
                        icon="pi pi-arrow-circle-right"
                        rounded
                        text
                        onClick={() => onOpen({
                            id: node.pathName,
                            title: node.title,
                            template: node.template
                        })}
                    />
                ].filter(it => !!it),
                title: (<label>
                    <input
                        className={styles.input}
                        value={node.title}
                        onChange={(event) => {
                            const name = event.target.value;

                            setState({
                                ...state,
                                treeData: changeNodeAtPath({
                                    treeData: state.treeData,
                                    path,
                                    getNodeKey: ({id}) => id,
                                    newNode: {...node, name},
                                })
                            });
                        }}
                    />
                </label>),
            })}
        />
    </ScrollPanel>
}