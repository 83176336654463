import React, {useCallback, useContext, useEffect, useState} from "react";
import {Splitter, SplitterPanel} from "primereact/splitter";
import dayjs from "dayjs";

import {Tree} from "./Tree";
import {MarkdownEditor} from "./MarkdownEditor";
import {BlocknoteEditor} from "./BlocknoteEditor";

import styles from "./styles.module.css";
import {ProjectList} from "./ProjectList";
import {Profile} from "./Profile";
import {Chips} from "primereact/chips";
import {Dropdown} from "primereact/dropdown";
import {SelectButton} from "primereact/selectbutton";
import {TreeView} from "./TreeView";
import {Button} from "primereact/button";
import {Badge} from "primereact/badge";
import {AppContext} from "../service/AppContext";
import {ImportEditor} from "./ImportEditor";
import {Dialog} from "primereact/dialog";
import {DataView} from "primereact/dataview";
import {Tag} from "primereact/tag";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import clsx from "clsx";

export function SaveChangesDialog({ onClose, changes, selectedBranch, onSaveChanges, onClearChanges }) {
    const [branchName, setBranchName] = useState(selectedBranch ?? "");
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const itemTemplate = (item) => <div className={styles.changed_item}>
        <span>{item[0]}</span>
        <div className={styles.item_changes}>
            {Object.keys(item[1]).map(changeType => <Tag value={changeType} />)}
        </div>
    </div>;

    const canSave = branchName !== 'main';

    const onCancel = () => {
        confirmDialog({
            message: <>Вы точно хотите отменить все изменения?</>,
            header: 'Подтвердите отмену изменений',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectLabel: 'Нет',
            acceptLabel: 'Да',
            accept: () => {
                setLoading(true);
                onClearChanges().then(() => {
                    setLoading(false);
                    onClose();
                });
            },
            reject: () => {}
        });
    };

    const onSave = () => {
        onSaveChanges({
            changes,
            branchName,
            comment
        }).then(() => {
            setLoading(false);
            onClose();
        });
    };

    return <Dialog
        header="Несохраненные изменения"
        visible={true}
        style={{ width: '50vw', height: '80vh' }}
        onHide={onClose}
        footer={<div className={styles.change_footer}>
            <Button
                label="Отменить все изменения"
                severity="danger"
                icon="pi pi-trash"
                onClick={onCancel}
            />
            <Button
                label="Сохранить в репозиторий"
                severity="success"
                icon="pi pi-save"
                onClick={onSave}
                disabled={!canSave || (comment.length < 3)}
            />
        </div>}
    >
        <form name="save-changes" className={styles.changes_form}>
            <div className="flex flex-column gap-2">
                <label htmlFor="branch">Имя ветки для сохранения:</label>
                <InputText
                    id="branch"
                    name="branch"
                    aria-describedby="branch-help"
                    value={branchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    className={clsx({
                        'p-invalid': !canSave
                    })}
                />
                {!canSave ? <small id="branch-help">
                    Нельзя сохранять в основную ветку.
                </small> : null}
            </div>
            <div className="flex flex-column gap-2">
                <label htmlFor="comment">Комментарий:</label>
                <InputTextarea
                    name="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={5}
                />
            </div>
        </form>
        <DataView value={Object.entries(changes)} itemTemplate={itemTemplate} paginator rows={5} />
        <ConfirmDialog />
    </Dialog>
}

export function Layout(props) {
    const [content, setContent] = useState({});
    const [tags, setTags] = useState([]);
    const [editor, setEditor] = useState('Markdown');
    const [projectView, setProjectView] = useState('contents');
    const [commits, setCommits] = useState([]);
    const [changes, setChanges] = useState({});
    const [selectedCommit, setSelectedCommit] = useState('HEAD');
    const [changesDialogOpen, setChangesDialogOpen] = useState(false);

    const { api, projectName, selectedBranch, draft } = useContext(AppContext);

    const onClearChanges = () => {
        return draft.clear();
    };

    const onSaveChanges = ({ changes, branchName, comment }) => {
        return draft.clear()
            .then(() => Promise.resolve(true));
    };

    const onContentChange = useCallback(({ action, id, ...meta }) => {
        const nextChanges = {
            ...changes,
            [id]: {
                ...(changes[id] ?? {}),
                [action]: meta
            }
        };
        setChanges(nextChanges);
        draft.set('changes', nextChanges);
    }, [changes, draft]);

    const getSeverity = (changes) => {
        if (changes === 0) return "secondary";
        else if (changes < 10) return "success";
        else if (changes < 20) return "warning";
        else return "danger";
    };

    useEffect(() => {
        if (content.id) api
            .getCommits(content.id, selectedBranch)
            .then(result => {
                setCommits(result.map(commit => ({
                    id: commit.id,
                    title: commit.message,
                    date: dayjs(commit.committed_date).format('DD.MM.YY')
                })))
            })
            .catch(err => {
                console.error(err);
            })
        else setCommits([]);

        if (draft && draft.has('changes')) {
            setChanges(draft.get('changes'));
        }
    }, [content, projectName, selectedBranch]);

    const changeCount = Object.keys(changes).length;

    return <Splitter style={{height: "100vh"}}>
        <SplitterPanel minSize={20} size={35} className="flex align-items-center justify-content-center">
            <div className={styles.treeview}>
                <div className={styles.project}>
                    <ProjectList onImport={() => setEditor('import')} />
                    <Profile />
                </div>
                <div className={styles.search}>
                    <Chips value={tags} onChange={(e) => setTags(e.value)} />
                </div>
                {(projectView === 'contents') ? <Tree onOpen={setContent} tags={tags} onChange={onContentChange} /> : null}
                {(projectView === 'templates') ? <TreeView onOpen={setContent} /> : null}
                {(projectView === 'plugins') ? <TreeView onOpen={setContent} /> : null}
                <div className={styles.treeFooter}>
                    <SelectButton
                        className={styles.footerButtons}
                        value={projectView}
                        onChange={(e) => setProjectView(e.value)}
                        options={[
                            { label: 'Контент', value: 'contents' },
                            { label: 'Шаблоны', value: 'templates' },
                            { label: 'Плагины', value: 'plugins' }
                        ]}
                    />
                </div>
            </div>
        </SplitterPanel>
        <SplitterPanel minSize={50} size={60} className="flex align-items-center justify-content-center">
            <div className={styles.editorview}>
                <div className={styles.editorHeader}>
                    <h2 style={{flexGrow:1}}>{content.title}</h2>
                    <Dropdown
                        options={[
                            { label: 'README.md', value: 'markdown' },
                            { label: 'OR.md', value: 'ors' },
                            { label: 'assets', value: 'assets' },
                            { label: 'blocks.yml', value: 'blocks' },
                            { label: 'examples', value: 'code' },
                            { label: 'meta.yml', value: 'meta' }
                        ]}
                        value={"markdown"}
                    />
                    <Dropdown
                        options={[
                            { label: 'Markdown', value: 'Markdown' },
                            { label: 'Blocknote', value: 'Blocknote' }
                        ]}
                        value={editor}
                        onChange={(e) => setEditor(e.value)}
                    />
                </div>
                <div className={styles.tabview}>
                    {(editor === 'Markdown') ? <MarkdownEditor
                        id={content.id}
                        onChange={onContentChange}
                        commitId={selectedCommit === 'HEAD' ? undefined : selectedCommit}
                    /> : null}
                    {(editor === 'Blocknote') ? <BlocknoteEditor
                        id={content.id}
                    /> : null}
                    {(editor === 'import') ? <ImportEditor onClose={() => setEditor('Markdown')} /> : null}
                </div>
            </div>
        </SplitterPanel>
        <SplitterPanel size={5} minSize={5}>
            <div className={styles.historyView}>
                <div className={styles.comments}>
                    <Button icon="pi pi-comment" text aria-label="Notification" style={{height: '39px'}}>
                        <Badge value="0"></Badge>
                    </Button>
                </div>
                <div className={styles.changes}>
                    <Button onClick={() => setChangesDialogOpen(true)} icon="pi pi-file-edit" severity={getSeverity(changeCount)} aria-label="Notification" style={{height: '33px'}} >
                        <Badge value={changeCount}></Badge>
                    </Button>
                </div>
                <div className={styles.history}>
                    {commits.map((commit, index) => {
                        if (index === 0) return <Button
                            size="small"
                            label={commit.date}
                            severity="success"
                            tooltip={commit.title}
                            tooltipOptions={{ position: 'left' }}
                            onClick={() => setSelectedCommit('HEAD')}
                        />;
                        else return <Button
                            size="small"
                            label={commit.date}
                            severity="secondary"
                            outlined
                            tooltip={commit.title}
                            tooltipOptions={{ position: 'left' }}
                            onClick={() => setSelectedCommit(commit.id)}
                        />;
                    })}
                </div>
            </div>
            { changesDialogOpen ? <SaveChangesDialog
                changes={changes}
                selectedBranch={selectedBranch}
                onClose={() => setChangesDialogOpen(false)}
                onSaveChanges={onSaveChanges}
                onClearChanges={onClearChanges}
            /> : null }
        </SplitterPanel>
    </Splitter>;
}
