import React, {useState, useEffect, useRef} from "react";
import styles from "./styles.module.css";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {useNotion} from "../service/Notion";
import clsx from "clsx";
import {ProgressSpinner} from "primereact/progressspinner";
import {Message} from "primereact/message";
import {ImportedTree} from "./Tree";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {MarkdownView} from "./MarkdownEditor";

export function ImportEditor({ onClose }) {
    const [pageUrl, setPageUrl] = useState("");
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [found, setFound] = useState(0);
    const [data, setData] = useState([]);
    const [preview, setPreview] = useState(null);
    const toast = useRef(null);

    const { notion, configure } = useNotion();

    useEffect(() => {
        if (token) {
            configure(token);
            localStorage.setItem('__notion_key', token);
        }
    }, [token]);

    useEffect(() => {
        const storedToken = localStorage.getItem('__notion_key');
        if (storedToken && storedToken.length > 0) {
            setToken(storedToken);
        }
    }, []);

    const onSearch = () => {
        setLoading(true);
        notion.getTree(pageUrl, () => {
            setFound((prev) => prev + 1);
        }).then(tree => {
            setLoading(false);
            setData(tree);
        }).catch(err => {
            console.error(err);
            toast.current.show({
                severity:'error',
                summary: 'Error',
                detail: err.message,
                life: 3000
            });
        });
    };

    const onOpenPage = (page) => {
        console.log(page);
        setPreview(page);

        notion.exportPage(page.id)
            .then(({ content }) => {
                setPreview({
                    ...page,
                    content
                });
            })
            .catch(err => {
                toast.current.show({
                    severity:'error',
                    summary: 'Error',
                    detail: err.message,
                    life: 3000
                });
            });
    };

    return <div className={styles.importer}>
        <Toolbar
            start={<React.Fragment>
                <InputText
                    value={pageUrl}
                    onChange={(e) => setPageUrl(e.target.value)}
                    placeholder="Адрес страницы в Notion"
                />
                <Password
                    value={token}
                    onChange={(e) => setToken(e.target.value)} feedback={false}
                    placeholder="Токен с доступом к странице"
                />
                <Button
                    icon="pi pi-search"
                    severity="success"
                    aria-label="Search"
                    onClick={onSearch}
                    disabled={!notion}
                    loading={loading}
                />
            </React.Fragment>}
            end={<Button
                icon="pi pi-times"
                severity="danger"
                aria-label="Cancel"
                onClick={onClose}
            />}
        />

        <div className={clsx({
            [styles.importer_wait]: loading,
            [styles.importer_content]: !loading
        })}>
            {loading
                ? <React.Fragment>
                    <ProgressSpinner />
                    <Message severity="info" text={`Загружено вложенных страниц: ${found}`} />
                </React.Fragment>
                : ((!loading && found > 0)
                    ? <ImportedTree
                            data={data}
                            onOpen={onOpenPage}
                        />
                    : <Message severity="info" text="Укажите токен и начальную страницу поиска" />
            )}
        </div>

        {preview ? <Dialog
            header={preview.title}
            visible={true}
            maximizable
            style={{ width: '50vw', height: '80vh' }}
            onHide={() => setPreview(false)}
        >
            {(!preview.content) ? <div className={styles.importer_wait}>
                    <ProgressSpinner/>
                    <Message severity="info" text="Загружаем страницу" />
                </div> :
                <MarkdownView
                    content={preview.content}
                />
            }
        </Dialog> : null }

        <Toast ref={toast} />
    </div>
}