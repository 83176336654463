export const stripHeaders = (content) => {
    let rows = content.split("\n");
    let skip = 0;
    for (let i = 0; i < rows.length; i++) {
        if (rows[i].trim() === "") {
            skip++;
            continue;
        }
        if (/^#\s/.test(rows[i])) {
            skip++;
        } else {
            break;
        }
    }
    return rows.slice(skip).join("\n");
};