import React, {useContext, useRef, useState} from "react";
import {AppContext} from "../service/AppContext";
import styles from "./styles.module.css";
import {Dropdown} from "primereact/dropdown";
import {Actions} from "./Actions";
import {ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {GitlabAPI} from "../service/Gitlab";

export function ProjectList({ onImport }) {
    const {
        projectsList,
        projectName,
        selectedProject,
        selectProject,
        setConfigured,
        branchesList,
        selectedBranch,
        selectBranch,
        deleteProject,
        isEditable
    } = useContext(AppContext);
    const items = [
        /*{
            label: 'Обновить',
            icon: 'pi pi-refresh',
            command: () => {
                //toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
            }
        },*/
        {
            label: 'Удалить',
            icon: 'pi pi-trash',
            command: () => {
                const accept = () => {
                    deleteProject(selectedProject).then(() => {
                        console.log(`Delete project ${selectedProject}`);
                    });
                };
                const reject = () => {};


                confirmDialog({
                    message: <>Вы точно хотите удалить проект <b>{projectName}</b>?</>,
                    header: 'Подтвердите удаление',
                    icon: 'pi pi-info-circle',
                    acceptClassName: 'p-button-danger',
                    rejectLabel: 'Нет',
                    acceptLabel: 'Да',
                    accept,
                    reject
                });
                //toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
            }
        },
        /*{
            label: 'Открыть',
            icon: 'pi pi-external-link',
            command: () => {
                //window.location.href = 'https://facebook.github.io/react/';
            }
        },*/
        {
            label: 'Импортировать',
            icon: 'pi pi-file-import',
            command: () => {
                if (onImport) onImport();
            }
        }
    ];

    return <div className={styles.selector}>
        <Dropdown
            value={selectedProject}
            onChange={(e) => {
                if (e.value === 'new') setConfigured(false);
                else selectProject(e.value);
            }}
            options={[
                ...projectsList,
                {label: 'Добавить проект', value: 'new', className: 'new-project'}
            ]}
        />
        <Dropdown
            value={selectedBranch}
            onChange={(e) => {
                selectBranch(e.value);
            }}
            options={[
                ...branchesList
            ]}
        />
        <Actions items={items}/>
        <ConfirmDialog />
    </div>
}