import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";

import MDEditor from "@uiw/react-md-editor";
import styles from "../App.module.css";
import {Popover} from "react-text-selection-popover";

import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import {ScrollPanel} from "primereact/scrollpanel";
import {CommentEditor} from "./Comments";
import {AppContext} from "../service/AppContext";
import {stripHeaders} from "../utils/utils";

export function MarkdownEditor({id, onChange, commitId}) {
    const [commentsVisible, setCommentsVisible] = useState(false);
    const [content, setContent] = useState();
    const [height, setHeight] = useState(document.body.offsetHeight - 42);
    const { api, selectedBranch, draft } = useContext(AppContext);

    useEffect(() => {
        if (id) {
            if (!commitId && draft && draft.has(id)) {
                setContent(draft.get(id));
            } else {
                api.getRawFile(id, commitId ?? selectedBranch)
                    .then(result => {
                        setContent(stripHeaders(result));
                    })
                    .catch(err => {
                        console.error(err);
                        setContent('');
                    })
            }
        } else {
            setContent('');
        }
    }, [id, selectedBranch, commitId]);

    return (
        <div style={{height: "calc(100% - 40px - 34px)"}} className="container" data-color-mode="light">
            <MDEditor
                value={content}
                onChange={(value) => {
                    setContent(value);
                    onChange({ action: 'edit', id });
                    draft.set(id, value);
                }}
                height={height}
                visibleDragbar={false}
                preview="preview"
                className={styles.editor}
            />
            <MDEditor.Markdown source={content} style={{whiteSpace: 'pre-wrap'}}/>
            <Popover
                render={
                    ({clientRect, isCollapsed, textContent}) => {
                        if (clientRect == null || isCollapsed) return null

                        // I'm using emotion for this example but you can use anything really
                        const style = {
                            position: 'absolute',
                            left: `${clientRect.left + clientRect.width / 2}px`,
                            top: `${clientRect.top - 40}px`
                        }

                        return createPortal(<div style={style}>
                            <Button
                                onClick={() => setCommentsVisible(true)}
                                icon="pi pi-comment"
                                rounded
                                raised
                            />
                        </div>, document.body)
                    }
                }
            />
            <Sidebar visible={commentsVisible} position="right" onHide={() => setCommentsVisible(false)}>
                <ScrollPanel style={{width: '100%', height: '100%'}}>
                    <CommentEditor onSave={() => {
                    }}/>
                </ScrollPanel>
            </Sidebar>
        </div>
    );
}

export function MarkdownView({content}) {
    return (
        <ScrollPanel style={{width: '100%', height: '100%'}}>
            <div data-color-mode="light">
                <MDEditor.Markdown source={content} style={{whiteSpace: 'pre-wrap'}}/>
            </div>
        </ScrollPanel>
    );
}