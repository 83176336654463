import React, {useContext, useEffect} from "react";
import {BlockNoteView, useBlockNote} from "@blocknote/react";

import styles from "../App.module.css";
import {AppContext} from "../service/AppContext";
import {stripHeaders} from "../utils/utils";

export function BlocknoteEditor({id}) {
    const { api, selectedBranch } = useContext(AppContext);
    const editor = useBlockNote({
        theme: "light"
    });

    useEffect(() => {
        const setBlocks = async (md) => {
            const blocks = await editor.markdownToBlocks(md);
            editor.replaceBlocks(editor.topLevelBlocks, blocks);
        };

        api.getRawFile(id, selectedBranch)
            .then(result => {
                setBlocks(stripHeaders(result));
            })
            .catch(err => {
                console.error(err);
                setBlocks('');
            })
    }, [id, selectedBranch]);

    return <BlockNoteView editor={editor} className={styles.blocknote}/>;
}