import {API} from "./API";
import yaml from "js-yaml";
import {ALL_PROJECTS_KEY, SELECTED_PROJECT_KEY} from "./useAPI";
import {Generator} from "./Generator";
import _ from "lodash";

export class GitlabAPI extends API {
    constructor(baseUrl, project, token, proxy = null) {
        super(baseUrl, proxy);
        this._setupAuth(token);

        this._projectName = project;
        this._project = null;
        this._user = null;
        this._branches = [];
        this._defaultBranch = 'main';
        this._generator = null;

        this._api = `/api/v4`;
    }

    get _projectID() {
        return this._project ? this._project.id : this._projectName;
    }

    get _apiProject() {
        return this._api + `/projects/${this._projectID}`;
    }

    get content() {
        return [...this._generator.content.values()]
            .map(item => _.pick(item, ['id','parentId','title','template']))
            .map(item => ({
                ...item,
                pathName: this._generator.getContentPath(item.id).slice(1)
            }));
    }

    init() {
        if (this._user && this._project) {
            throw Error('INIT_COMPLETE');
        }

        return Promise.all([
            this.getUser(),
            this.getProject()
        ]).then(([user, project]) => {
            this._user = user;
            this._project = project;
            return this.getBranches();
        }).then((branches) => {
            this._branches = branches;
            this._defaultBranch = branches.filter(branch => branch.default).pop().name;
            return this.getRawFile('CONTENT.yml');
        }).then((content) => {
            this.rebuild(content);
            return Promise.resolve(this);
        });
    }

    rebuild(content) {
        this._generator = new Generator('/', content);
        this._generator.initTree();
    }

    getUser() {
        return this._get(this._api + '/user');
    }

    getProject() {
        return this._get(this._apiProject);
    }

    getBranches() {
        return this._get(this._apiProject + '/repository/branches');
    }

    getFile(pathName, ref = this._defaultBranch) {
        return this._get(this._apiProject + `/repository/files/${encodeURIComponent(pathName)}`, {ref})
            .then(result => {
                if (!result) return Promise.reject('NOT_FOUND');
                else {
                    const content = this._b64decode(result.content);
                    if (!/\.yml$/.test(pathName)) return Promise.resolve(content);
                    else {
                        try {
                            let parsed = yaml.load(content);
                            return Promise.resolve(parsed);
                        } catch (err) {
                            return Promise.reject('UNREADABLE');
                        }
                    }
                }
            });
    }

    getCommits(pathName, ref = this._defaultBranch) {
        return this._get(this._apiProject + `/repository/commits`, {
            ref,
            path: encodeURIComponent(pathName)
        });
    }

    getBlame(pathName, ref = this._defaultBranch) {
        return this._get(this._apiProject + `/repository/files/${encodeURIComponent(pathName)}/blame`, {ref});
    }

    getRawFile(pathName, ref = this._defaultBranch) {
        return this._get(this._apiProject + `/repository/files/${encodeURIComponent(pathName)}/raw`, {ref}, 'text').then(content => {
            if (!/\.yml$/.test(pathName)) return Promise.resolve(content);
            else {
                try {
                    let parsed = yaml.load(content);
                    return Promise.resolve(parsed);
                } catch (err) {
                    return Promise.reject('UNREADABLE');
                }
            }
        });
    }

    static configure(url, token, proxy) {
        let {project, host} = this.getProjectName(url);
        return new this(host, project, token, proxy);
    }

    static getProjectName(url) {
        let u = new URL(url);
        let project = encodeURIComponent(u.pathname.slice(1).replace(/(\.git|\/)$/, ""));
        let host = u.origin;
        return {project, host};
    }

    static getSelectedProject() {
        try {
            return JSON.parse(localStorage.getItem(SELECTED_PROJECT_KEY));
        } catch (err) {
            return null;
        }
    }

    static setSelectedProject(url, token) {
        localStorage.setItem(SELECTED_PROJECT_KEY, JSON.stringify({url, token}));
    }

    static getStoredProjects() {
        try {
            return JSON.parse(localStorage.getItem(ALL_PROJECTS_KEY));
        } catch (err) {
            return {};
        }
    }

    static setStoredProjects(projects) {
        localStorage.setItem(ALL_PROJECTS_KEY, JSON.stringify(projects));
    }

    static deleteStoredProject(url) {
        const projects = this.getStoredProjects();
        delete projects[url];

        const {url: selectedUrl} = this.getSelectedProject() ?? {};
        this.setStoredProjects(projects);

        if (selectedUrl === url) {
            const nextUrl = Object.keys(projects).pop();
            if (nextUrl) return {
                url: nextUrl,
                token: projects[nextUrl]
            };
        } else return null;
    }
}