import pick from "lodash/pick";

export class API {
    constructor(baseUrl, proxy = null) {
        this._baseUrl = baseUrl;
        this._options = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        if (proxy) {
            this._baseUrl = proxy;
            this._options.headers["Target-URL"] = baseUrl;
        }
    }

    static fetchProxy = (targetUrl) => (url, { headers, ...options } = {}) => {
        return fetch(url, {
            ...options,
            headers: {
                ...headers,
                "Target-URL": targetUrl
            }
        });
    }

    _setupAuth(token) {
        this._options.headers['Authorization'] = `Bearer ${token}`;
    }

    _handleResponse = (method = 'json') => (res) => {
        if (!res.ok) return Promise.reject(pick(res, ['ok', 'status', 'statusText', 'headers']));
        else {
            return res[method]();
        }
    };

    _b64decode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(
            atob(str)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(""),
        );
    }

     _b64encode(str) {
        return btoa(
            encodeURIComponent(str).replace(
                /%([\dA-F]{2})/g,
                function toSolidBytes(match, p1) {
                    return String.fromCharCode(Number("0x" + p1));
                },
            ),
        );
    }

    _get(uri, args = {}, contentType = 'json') {
        let fullUri = this._baseUrl + uri;
        let query = (new URLSearchParams(args)).toString();
        if (query.length > 0) fullUri += '?' + query;
        return fetch(fullUri, {
            ...this._options
        }).then(this._handleResponse(contentType));
    }

    _post(uri, data) {
        return fetch(this._baseUrl + uri, {
            ...this._options,
            body: JSON.stringify(data)
        }).then(this._handleResponse);
    }
}