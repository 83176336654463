import React, {useState} from "react";
import {Card} from "primereact/card";
import styles from "../App.module.css";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

export function ConfigureProject({onSubmit, onCancel}) {
    const [url, setUrl] = useState("");
    const [token, setToken] = useState("");

    return (
        <Card title="Подключение проекта" className={styles.configure}>
            <div className="flex align-items-center justify-content-center">
                <form onSubmit={e => {
                    e.preventDefault();
                    onSubmit({url, token});
                    return false;
                }}>
                    <label htmlFor="email" className="block text-900 font-medium mb-2">Адрес репозитория</label>
                    <InputText
                        id="email"
                        type="url"
                        autoComplete="off"
                        placeholder="HTTP адрес"
                        className="w-full mb-3"
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                    />

                    <label htmlFor="password" className="block text-900 font-medium mb-2">Токен</label>
                    <InputText
                        id="password"
                        type="password"
                        autoComplete="off"
                        placeholder="Персональный или проектный токен"
                        className="w-full mb-3"
                        value={token}
                        onChange={e => setToken(e.target.value)}
                    />

                    <div className={styles.buttons}>
                        {onCancel ? <Button
                            type="button"
                            label="Отменить"
                            icon="pi pi-times"
                            outlined
                            onClick={onCancel}
                        /> : null }

                        <Button
                            type="submit"
                            label="Добавить"
                            icon="pi pi-plus"
                        />
                    </div>
                </form>
            </div>
        </Card>
    );
}