import { Client } from '@notionhq/client';
import _ from 'lodash';
import { NotionToMarkdown } from "notion-to-md";
import {API} from "./API";
import {NOTION_API, PATH_DELIMITER, PROXY_URL} from "../utils/constants";
import {useMemo, useState} from "react";

export class Notion {
	constructor({ key }) {
		this.notion = new Client({
			auth: key,
			fetch: API.fetchProxy(NOTION_API),
			baseUrl: PROXY_URL
		});
		this.n2m = new NotionToMarkdown({ 
			notionClient: this.notion,
			config:{
				separateChildPage: true,
				parseChildPages: true // default: parseChildPages
			}
		});
		this.n2m.setCustomTransformer("child_page", async (block, md) => {
			const { child_page, id } = block;
			if (!child_page?.title) return "";
			return md.link(child_page.title, `#${id}`);
		});
	}

	async search(query) {
		const response = await this.notion.search({ query, filter: {
			value: "page",
			property: "object"
		}, page_size: 10});
		return response.results
			.filter(item => item.object === 'page')
			.map(this._getPageInfo)
			.filter(page => page.title ? page.title.includes(query) : false)
	}

	_getPageInfo(page) {
		return {
			id: page.id,
			url: page.url,
			title: _.get(page, 'properties.title.title[0].plain_text')
		};
	}

	async loadDeepBlocks(source, maxLevel, level = 1) {
		let result = [];
		for (const item of source) {
			switch (item.type) {
				case 'child_database':
					try {
						const response = await this.notion.databases.query({
							database_id: item.id
						});
						if (response && response.results) {
							const dbPages = [];
							for (const el of response.results.filter(it => it.object === 'page')) {
								const pageResponse = await this.notion.pages.retrieve({
									page: el.id
								});
								const page = this._getPageInfo(pageResponse);
								dbPages.push({
									type: 'child_page',
									id: page.id,
									child_page: {
										title: page.title
									}
								});
							}
							result = [...result, ...dbPages];
							continue;
						}
					} catch (err) {
						console.error(`db: ${item.id} not available`);
					}
				case 'synced_block':
					try {
						const syncFrom = (item.synced_block.synced_from === null)
							? item.id
							: (item.synced_block.synced_from.type === 'block_id')
								? item.synced_block.synced_from.block_id
								: null;
						if (syncFrom) {
							const syncedResponse = await this.getBlocks(syncFrom);
							if (syncedResponse && syncedResponse.results) {
								if (level === maxLevel) {
									result = [...result, ...syncedResponse.results];
									continue;
								}
								const syncedItems = await this.loadDeepBlocks(syncedResponse.results, maxLevel, level + 1);
								result = [...result, ...syncedItems];
								continue;
							}
						}
					} catch (err) {
						console.log(`block: ${item.id} deep loading failed`);
					}
				default:
					result.push(item);
			}
		}
		return result;
	}

	_getChildPages(blocks) {
		return blocks
			.filter(item => item.type === 'child_page')
			.map(page => ({
				id: page.id,
				title: _.get(page, 'child_page.title')
			}));
	}

	_getTextBlocks(blocks) {
		return blocks
			.filter(item => item.type === 'paragraph')
			.map(item => _.get(item, "paragraph.rich_text"))
			.filter(item => item.length)
			.reduce((a, c) => [...a, ...c], []);
	}

	_getMentions(texts) {
		return texts
			.filter(block => block.type === "mention" && _.get(block, "mention.type") === "page")
			.map(mention => ({
				id: _.get(mention, "mention.page.id"),
				title: _.get(mention, 'plain_text')
			}));
	}

	_getLinks(texts) {
		return texts
			.filter(block => block.type === "text" && block.href)
			.map(mention => ({
				url: mention.href,
				title: _.get(mention, 'plain_text')
			}));
	}

	async getTree(pageUrlOrId, onItem) {
		const page_id = /^http/ig.test(pageUrlOrId) 
			? this._getId(pageUrlOrId) 
			: pageUrlOrId;

		const response = await this.getBlocks(page_id);
		const blocks = await this.loadDeepBlocks(response.results, 3);
		const pages = this._getChildPages(blocks);

		for (const page of pages) {
			if (onItem) onItem(page);
			page.children = await this.getTree(page.id, onItem);
			page.template = this._getTemplate(page.title);
			page.imported = true;
		}

		return pages;
	}

	_getTemplate(title) {
		if (/^Тема/ig.test(title)) return 'theme';
		else return 'lesson';
	}

	async getBlocks(page_id) {
		return this.notion.blocks.children.list({
			block_id: page_id
		});
	}

	_getId(pageUrl) {
		try {
			const raw = pageUrl.match(/[a-z\d]{32}$/).shift();
			return [...raw.matchAll(/^(.{8})(.{4})(.{4})(.{4})(.{12})/g)][0].slice(1,6).join("-");
		} catch (err) {
			console.log(err);
			return null;
		}
	}

	async getRawDataPage(pageUrlOrId) {
		const page_id = /^http/ig.test(pageUrlOrId)
			? this._getId(pageUrlOrId)
			: pageUrlOrId;
		const pageResponse = await this.notion.pages.retrieve({page_id});
		const page = this._getPageInfo(pageResponse);
		const blocksResponse = await this.getBlocks(page_id);

		const title = page.title.replace(/[/\\?%*:|"<>]/g, '');

		return {
			title,
			pageResponse,
			blocksResponse
		};
	}

	async getPage(pageUrlOrId) {
		const page_id = /^http/ig.test(pageUrlOrId) 
			? this._getId(pageUrlOrId) 
			: pageUrlOrId;
		const pageResponse = await this.notion.pages.retrieve({ page_id });
		const page = this._getPageInfo(pageResponse);
		
		const blocksResponse = await this.getBlocks(page_id);
		const blocks = await this.loadDeepBlocks(blocksResponse.results, 3);

		const childs = this._getChildPages(blocks);
		const paragraphsBlocks = this._getTextBlocks(blocks);
		const mentions = this._getMentions(paragraphsBlocks);
		const links = this._getLinks(paragraphsBlocks);

		return {
			...page,
			childs,
			mentions,
			links
		};
	}

	async exportPage(pageUrlOrId, saveTo) {
		const page_id = /^http/ig.test(pageUrlOrId)
			? this._getId(pageUrlOrId)
			: pageUrlOrId;
		const response = await this.notion.pages.retrieve({ page_id });
		const page = this._getPageInfo(response);
		const title = page.title.replace(/[/\\?%*:|"<>]/g, '');
		const targetPath = [saveTo, title].join(PATH_DELIMITER);

		const mdblocks = await this.n2m.pageToMarkdown(page_id);
  		const mdString = this.n2m.toMarkdownString(mdblocks);

		return {
			target: targetPath,
			content: mdString.parent ?? ""
		};
	}

	async * exportPageTree(pageUrlOrId, saveTo) {
		const page_id = /^http/ig.test(pageUrlOrId)
			? this._getId(pageUrlOrId)
			: pageUrlOrId;

		const { target, content } = await this.exportPage(page_id, saveTo);
		yield { target, content };

		const response = await this.getBlocks(page_id);
		const blocks = await this.loadDeepBlocks(response.results, 3);
		const pages = this._getChildPages(blocks);

		for (const page of pages) {
			await this.exportPageTree(page.id, target);
		}
	}
}

const clients = new Map();

export function useNotion() {
	const [selected, setSelected] = useState(null);

	const notion = useMemo(() => {
		if (selected && clients.has(selected)) {
			return clients.get(selected);
		} else {
			return null;
		}
	}, [selected]);

	const configure = (token) => {
		if (!clients.has(token)) {
			console.log(`Notion configured.`);
			clients.set(token, new Notion({ key: token }));
			setSelected(token);
		}
	};

	return { notion, configure };
}