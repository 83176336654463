import React, {useCallback, useEffect, useMemo, useState} from "react";
import * as Y from 'yjs';
import { IndexeddbPersistence } from 'y-indexeddb';

import {GitlabAPI} from "./Gitlab";
import {PROXY_URL} from "../utils/constants";

export const SELECTED_PROJECT_KEY = '__selected-project';
export const ALL_PROJECTS_KEY = '__all-projects';

let api = null;
let ydoc = null;
let ymap = null;
let provider = null;

export function useDraft(project, branch) {
    const [state, setState] = useState(null);

    useEffect(() => {
        if (project && branch) {
            ydoc = new Y.Doc();
            ymap = ydoc.getMap();
            provider = new IndexeddbPersistence(`${project}#${branch}`, ydoc);
            provider.on('synced', () => {
                console.log(`Draft synced.`);
                setState({
                    set: (key, value) => ymap.set(key, value),
                    has: (key) => ymap.has(key),
                    get: (key) => ymap.get(key),
                    clear: () => {
                        return provider.clearData();
                    }
                });
            });
        }
    }, [project, branch]);

    return state;
}

export function useAPI() {
    const [isConfigured, setConfigured] = useState(false);
    const [projects, setProjects] = useState({});
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState("main");
    const draft = useDraft(selectedProject, selectedBranch);

    const projectsList = useMemo(() => {
        return Object.keys(projects).map(url => ({
            label: decodeURIComponent(GitlabAPI.getProjectName(url).project).split('/').pop(),
            value: url
        }));
    }, [projects]);

    const branchesList = useMemo(() => {
        return api ? api._branches.map(branch => ({
            label: branch.name,
            value: branch.name,
            commit: branch.commit.id,
            isDefault: branch.default
        })) : [];
    }, [selectedProject]);

    const isEditable = useMemo(() => {
        if (branchesList.length === 0) return false;
        const currentBranch = branchesList.filter(b => b.label === selectedBranch).pop();
        return !currentBranch?.isDefault;
    }, [branchesList, selectedBranch]);

    const userProfile = useMemo(() => {
        return api ? {
            avatar: api._user.avatar_url,
            email: api._user.commit_email,
            name: api._user.name
        } : {
            avatar: "https://placehold.co/250?text=A",
            name: "",
            email: ""
        }
    }, [selectedProject]);

    const projectName = useMemo(() => {
        return selectedProject ? decodeURIComponent(GitlabAPI.getProjectName(selectedProject).project) : 'Unknown';
    }, [selectedProject]);

    const reset = () => {
        setProjects({});
        setSelectedProject(null);
        setSelectedBranch('main');
        setConfigured(false);
    };

    const addProject = (url, token) => {
        return configure(url, token).then(() => {
            const projects = GitlabAPI.getStoredProjects();
            const nextProjects = {
                ...projects,
                [url]: token
            };
            setProjects(nextProjects);
            GitlabAPI.setStoredProjects(nextProjects);
        });
    };

    const selectProject = (url) => {
        const token = projects[url];
        if (token) return configure(url, token);
        else return Promise.reject();
    };

    const selectBranch = (name) => {
        const branch = branchesList.filter(branch => branch.value === name).pop();
        if (branch) return setSelectedBranch(name);
        else return Promise.reject();
    };

    const deleteProject = (project) => {
        const { url, token } = GitlabAPI.deleteStoredProject(project) ?? {};
        if (!url) {
            reset();
            return Promise.resolve();
        } else return configure(url, token).then(() => {
            const nextProjects = GitlabAPI.getStoredProjects();
            setProjects(nextProjects);
        });
    };

    const configure = (url, token) => {
        api = GitlabAPI.configure(
            url, token,
            PROXY_URL
        );

        return api.init()
            .then(() => {
                setConfigured(true);
                GitlabAPI.setSelectedProject(url, token);
                setSelectedProject(url);
                if (!api._branches.map(b => b.name).includes(selectedBranch)) {
                    setSelectedBranch(api._defaultBranch);
                }
                return Promise.resolve();
            })
            .catch(err => {
                console.error(err);
            })
    };

    const preload = useCallback(() => {
        try {
            const { url, token } = GitlabAPI.getSelectedProject();
            configure(url, token).then(() => {
                console.log(`Preload project.`);
                const projects = GitlabAPI.getStoredProjects();
                const nextProjects = {
                    ...projects,
                    [url]: token
                };
                setProjects(nextProjects);
                setSelectedBranch(api._defaultBranch);
                GitlabAPI.setStoredProjects(nextProjects);
            });
        } catch (err) {
            console.log('Project not configured.');
        }
    }, []);

    return {
        userProfile,
        projectName,
        preload,
        isConfigured,
        setConfigured,
        configure,
        addProject,
        isEditable,
        api,
        draft,
        projectsList,
        selectProject,
        selectedProject,
        branchesList,
        selectedBranch,
        selectBranch,
        deleteProject
    };
}