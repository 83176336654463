import React, {useState} from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";

export function CommentEditor({onSave}) {
    const [value, setValue] = useState("");

    return <div>
        <InputTextarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            rows={5}
            style={{width: "100%"}}
        />
        <Button label="Save" onClick={() => onSave(value)}/>
    </div>
}

function CommentsSidebar() {

}