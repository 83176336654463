import _ from "lodash";
import path from "path-browserify";

export class Generator {
    static TEMPLATES_DIR = '.templates';

    constructor(root, contentTree) {
        this.root = root;
        //Актуальный контент
        this.contentTree = contentTree

        //Смердженный с файловой системой контент
        this.content = new Map();
    }

    _isLink(name, content) {
        return _.isString(content) && /^\$/.test(content);
    }

    _isProject(name) {
        return name.includes(' Проект ');
    }

    _isArchive(exists) {
        return /^\.АРХИВ /i.test(exists[1]);
    }

    _rootPath(to) {
        return path.join(this.root, to);
    }

    _clearPath(name) {
        return name.replaceAll(/[*?<>:"\/\\|]/ig, "");
    }

    _parseName(name) {
        if (/^\d{2}\s/i.test(name)) {
            return [name.slice(0, 2), name.slice(3).trim()];
        } else {
            return [null, name.trim()]
        }
    }

    _getID(items) {
        return items.map(item => {
            const [order, title] = this._parseName(item);
            return order ?? title;
        }).join('/');
    }

    getContentPath(id, file = 'README.md') {
        if (!this.content.has(id)) return null;

        return path.join(this.content.get(id).destination, file);
    }

    _addContent(breadcrumbs, template, name, sourceData = {}, existsData = {}, parentId = null) {
        const contentPath = path.join(...breadcrumbs);
        const destination = this._rootPath(contentPath);

        let data = _.cloneDeep(sourceData);
        if (data.link) {
            const linkPath = this._rootPath(data.link.substring(1));
            data.link = encodeURI(path.relative(destination, linkPath).split(path.sep).join('/'));
        }
        if (!data.title) {
            const [order, title] = this._parseName(name);
            data.title = title;
            data.order = order;
        }

        const contentID = this._getID(breadcrumbs);


        this.content.set(contentID, Object.assign({
            id: contentID,
            parentId: parentId,
            title: data.title,
            //Шаблон для создания контента, он же метка присутствия в дереве CONTENT.yml
            template: (template === 'theme' && this._isProject(name)) ? 'project' : template,
            //Разобранное имя и порядковый номер из файловой системы [04, Test]
            exists: false,
            //Реальный путь до контента в файловой системе
            source: null,
            //Путь по дереву в виде массива родителей, включая текущий итем
            breadcrumbs,
            //Какой должен быть путь в файловой системе
            destination,
            //Данные для шаблонов
            data
        }, existsData));

        return contentID;
    }

    initTree() {
        //Разбираем исходное дерево в CONTENT.yml
        //@TODO: Отрефакторить инициализацию
        let profId = null;
        for (const profession in this.contentTree) { //COMMON
            let professionPath = [this._clearPath(profession)];
            profId = this._addContent(professionPath, 'profession', profession);

            for (const sprint in this.contentTree[profession]) { //Основы JavaScript
                const sprintContent = this.contentTree[profession][sprint];
                let sprintPath = [...professionPath, this._clearPath(sprint)];

                if (this._isLink(sprint, sprintContent, sprintPath)) {
                    this._addContent(sprintPath, 'link', sprint, {link: sprintContent}, undefined, profId);
                    continue;
                }

                let sprintId = this._addContent(sprintPath, 'sprint', sprint, undefined, undefined, profId);

                if (_.isPlainObject(sprintContent)) for (const theme in sprintContent) { //Вспомнить все
                    const themeContent = sprintContent[theme];
                    let themePath = [...sprintPath, this._clearPath(theme)];

                    if (this._isLink(sprint, sprintContent, themePath)) {
                        this._addContent(themePath, 'theme', theme, undefined, undefined, sprintId);
                        continue;
                    }

                    let themeId = this._addContent(themePath, 'theme', theme, undefined, undefined, sprintId);

                    if (_.isArray(themeContent)) for (const lesson of themeContent) { //Урок 1
                        if (_.isPlainObject(lesson)) {
                            const lessonName = Object.keys(lesson).shift();
                            const link = Object.values(lesson).shift();
                            this._addContent([...themePath, lessonName], 'link', lessonName, {link: link}, undefined, themeId);
                            continue;
                        }

                        if (_.isString(lesson)) {
                            this._addContent([...themePath, lesson], 'lesson', lesson, undefined, undefined, themeId);
                            continue;
                        }

                        //this.log(`Ошибка в уроке`, lesson);
                    }
                }
            }
        }
    }
}