import React, {useEffect} from "react";
import "@blocknote/core/style.css";
import styles from './App.module.css';
import {Layout} from "./components/Layout";
import {useAPI} from "./service/useAPI";
import {ConfigureProject} from "./components/ConfigureProject";
import {AppContext} from "./service/AppContext";

function App() {
    const state = useAPI();

    useEffect(() => {
        state.preload();
    }, []);

    return (
        <AppContext.Provider value={state}>
            <div className={styles.app}>
                {state.isConfigured ? <Layout /> : <ConfigureProject
                    onSubmit={({ url, token }) => {
                        state.addProject(url, token);
                    }}
                    onCancel={ state.selectedProject ? () => {
                        state.setConfigured(true);
                    } : undefined }
                />}
            </div>
        </AppContext.Provider>
    );
}

export default App;
