import {SpeedDial} from "primereact/speeddial";
import React from "react";

export function Actions({items}) {
    return <div style={{position: 'relative'}}>
        <SpeedDial
            model={items}
            direction="right"
            transitionDelay={80}
            showIcon="pi pi-bars"
            hideIcon="pi pi-times"
            buttonClassName="p-button-text"
            style={{left: '-0.5rem', top: '-0.4rem', zIndex: 100}}
        />
    </div>
}